import { useJsonld } from '#jsonld';

export function useStructuredFAQ(faq) {
    if (faq.length === 0) return;
    const mainEntity = [];
    for (let i = 0; i < faq.length; i++)
        mainEntity.push({
            '@type': 'Question',
            name: faq[i].question,
            acceptedAnswer: {
                '@type': 'Answer',
                text: faq[i].answer,
            },
        });
    useJsonld({
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: mainEntity,
    });
}

export function getFAQQuestions(page) {
    const accordion_blocks = page?.value?.body?.filter(
        (block) => block.type === 'accordion_block',
    );
    const faq = [];
    if (accordion_blocks) {
        for (let accordion_block of accordion_blocks) {
            for (let item of accordion_block.value?.items) {
                faq.push({ question: item.title, answer: item.content });
            }
        }
    }
    return faq;
}
