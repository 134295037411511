import { useJsonld } from '#jsonld';

export function useStructuredVideo(page) {
    const videoBlocks = page?.body?.filter(
        (block) => block.type === 'video_block',
    );
    const time = page?.body?.find((o) => o.type === 'recipe_time_block');
    if (videoBlocks && !time) {
        for (let videoBlock of videoBlocks) {
            const name = videoBlock.value?.title
                ? videoBlock.value?.title
                : page?.title;

            if (videoBlock.value?.source?.type === 'youtube') {
                jsonVideo(
                    name,
                    videoBlock.value?.text,
                    `https://img.youtube.com/vi/${videoBlock?.value?.source?.value?.video?.id}/0.jpg`,
                    `https://www.youtube.com/embed/${videoBlock?.value?.source?.value?.video?.id}`,
                    page?.meta?.first_published_at,
                    null,
                );
            } else if (
                videoBlock.value?.source?.value?.video?.type === 'video/mp4'
            ) {
                jsonVideo(
                    name,
                    videoBlock.value?.text,
                    null,
                    null,
                    page?.meta?.first_published_at,
                    videoBlock.value?.source?.value?.video?.src,
                );
            }
        }
    }
    if (page?.video) {
        const video = page?.video;
        const video_id = video?.overlay?.value?.video?.id;
        if (video_id) {
            jsonVideo(
                page?.title,
                page?.video?.description,
                `https://img.youtube.com/vi/${video_id}/0.jpg`,
                `https://www.youtube.com/embed/${video_id}`,
                page?.meta?.first_published_at,
            );
        }
    }
}

function jsonVideo(
    name,
    description,
    thumbnailUrl,
    embedUrl,
    uploadDate,
    contentUrl,
) {
    useJsonld({
        '@context': 'https://schema.org',
        '@type': 'VideoObject',
        name: name,
        description: description,
        ...(thumbnailUrl && { thumbnailUrl: thumbnailUrl }),
        ...(embedUrl && { embedUrl: embedUrl }),
        ...(contentUrl && { contentUrl: contentUrl }),
        uploadDate: uploadDate,
    });
}
