export function useCanonical() {
    const route = useRoute();
    const config = useRuntimeConfig();
    const canonical = computed(() => {
        let path;
        if (route.query?.page) {
            path = route.fullPath;
        } else {
            path = route.path;
        }
        return `${config.public.domain}${path}`;
    });
    useHead({
        link: {
            hid: 'canonical',
            rel: 'canonical',
            href: canonical.value,
        },
    });
}
